import { Injectable, inject } from '@angular/core';

import { WINDOW } from '../tokens/window.token';
import { WindowSize } from '../types/window.type';

/**
 * Декоратор Injectable используется для указания, что сервис WindowService должен быть предоставлен в корневом инжекторе.
 * @param {Object} options - Объект с параметрами
 * @param {string} options.providedIn - Место, где сервис должен быть предоставлен (в данном случае 'root')
 */
@Injectable({ providedIn: 'root' })
export class WindowService {
  /**
   * Устанавливает окно как только для чтения и внедряет его значение с помощью функции inject
   * @type {WINDOW}
   */
  readonly #window = inject(WINDOW);

  /**
   * Перенаправляет на указанный URL./**
   * Функция для получения полного URL с источником
   * @param {string} url - URL, к которому нужно добавить источник
   * @returns {string} - Полный URL с источником
   */
  getUrlWithOrigin(url: string): string {
    return `${this.#window.location.origin}${url}`;
  }

  /**
   * Перенаправляет на указанный URL.
   *
   * @param {string} url - URL, на который нужно перенаправить.
   * @param {boolean} [origin=false] - Флаг, указывающий, нужно ли добавлять исходный URL.
   * @returns {void}
   */
  redirect(url: string, origin = false): void {
    this.#window.location.href = origin ? this.getUrlWithOrigin(url) : url;
  }

  /**
   * Возвращает размеры окна.
   * @returns размеры окна.
   */
  getWindowSize(): WindowSize {
    return { width: this.#window.innerWidth, height: this.#window.innerHeight };
  }
}
